<template>
  <v-dialog
    :value="value"
    fullscreen
    max-width="800px"
    @keydown.esc="$emit('input', false)"
    no-click-animation
    scrollable
    persistent
  ><v-card v-if="contract">
    <v-toolbar color="nyGreen" dark class="mb-0">
      <v-toolbar-title class="nyGreen--text text--darken-4 poppins-semibold">
        {{ contract.Title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn color="nyGreen darken-2" @click="$emit('input', false)">
          <v-icon>fas fa-times-circle</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
<!--    <div class="my-3 text-center">-->
<!--      <v-btn large color="nyGreen" @click="$emit('input', false)">{{$t("common.back")}}</v-btn>-->
<!--    </div>-->
    <v-card-text class="pa-4 poppins-regular" v-html="contract.ContractText">
    </v-card-text>
    <div class="my-3 text-center">
      <v-btn large color="nyGreen" @click="$emit('input', false)">{{$t("common.back")}}</v-btn>
    </div>
  </v-card>
  </v-dialog>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import { mapActions } from "vuex";

export default {
	name: "ContractViewerDialog",
	props: {
		value: {
			type: Boolean,
			required: true
		},
		ContractTypeId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			contract: null
		}
	},

	created() {
		this.PatientContractController = new CrudClient("Patients/Contracts");
	},
	async mounted() {
		this.contract = await this.PatientContractController.Get(this.ContractTypeId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
	}
}
</script>
