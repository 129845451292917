<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @keydown.esc="$emit('input', false)"
    persistent
    no-click-animation
  >
    <v-card>
      <PersonalizedContractViewerDialog v-model="showNutriyouContract" :ContractTypeId="2"></PersonalizedContractViewerDialog>
      <PersonalizedContractViewerDialog v-model="showNutritionistContract" :ContractTypeId="3"></PersonalizedContractViewerDialog>
      <v-card-text>
        <div class="py-4">
          <h3 class="text-center poppins-semibold" style="font-size: 1.2rem">Privacy</h3>
        </div>
        <v-checkbox v-model="privacyNutritionistAccepted" :error-messages="errorMessagePrivacyNutritionist">
          <template v-slot:label>
            <span @click.prevent.stop="showNutritionistContract = true">
            {{ $t("i_accept_nutritionist_privacy_policy") }}</span>
          </template>
        </v-checkbox>
        <v-checkbox v-model="privacyNutriyouAccepted" :error-messages="errorMessagePrivacyNutriyou">
          <template v-slot:label>
             <span @click.prevent.stop="showNutriyouContract = true">
               {{ $t("i_accept_nutriyou_privacy_policy") }}</span>
          </template>
        </v-checkbox>
        <v-btn color="primary" large block @click="AcceptPrivacyContracts" :loading="sendingRequest">{{ $t("common.accept")}}</v-btn>

       <v-btn  class="mt-4" large block  outlined color="primary" text @click="$emit('input', false)">{{ $t("common.back")}}</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonalizedContractViewerDialog from "@/views/Patients/components/PersonalizedContractViewerDialog.vue";
import CrudClient from "@/services/CrudClient";
export default {
	name: "PrivacyContractsDialog",
	components: { PersonalizedContractViewerDialog },
	props: {
		value: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			privacyNutritionistAccepted: false,
			privacyNutriyouAccepted: false,
			showNutritionistContract: false,
			showNutriyouContract: false,
			errorMessagePrivacyNutriyou: null,
			errorMessagePrivacyNutritionist: null,
			sendingRequest: false

		}
	},
	created() {
		this.PatientContractController = new CrudClient("Patients/Contracts");
	},
	methods: {
		async AcceptPrivacyContracts() {
			this.errorMessagePrivacyNutriyou = null;
			this.errorMessagePrivacyNutritionist = null;

			try {
				if (!this.privacyNutriyouAccepted) {
					this.errorMessagePrivacyNutriyou = "Privacy Servizio Nutriyou é obbligatoria.";
				}

				if (!this.privacyNutritionistAccepted) {
					this.errorMessagePrivacyNutritionist = "Informativa Privacy Nutrizionista é obbligatoria.";
				}

				if (this.errorMessagePrivacyNutriyou || this.errorMessagePrivacyNutritionist) {
					return;
				}

				this.sendingRequest = true;

				await this.PatientContractController.Post(null, null, "AcceptPrivacy");
				this.$emit("contractsAccepted");

				this.$emit("input", false);
				// AcceptPrivacy
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.sendingRequest = false;
			}
		}
	}

}
</script>
<i18n>
{
  "it": {
    "i_accept_nutritionist_privacy_policy": "Informativa Privacy Nutrizionista",
    "i_accept_nutriyou_privacy_policy": "Privacy Servizio Nutriyou"
  }
}
</i18n>
