const routes = [{
	path: "/Admin",
	component: () => import(/* webpackChunkName: "AdminArea" */ "./Index.vue")
},
{
	path: "/Admin/Bookings",
	component: () => import(/* webpackChunkName: "AdminArea" */ "./Bookings.vue")
},
{
	path: "/Admin/Patients/:PatientUserId/Events",
	component: () => import(/* webpackChunkName: "AdminArea" */ "./components/PatientEventsViewer.vue")
},
{
	path: "/Admin/TallyWebhooks",
	component: () => import(/* webpackChunkName: "AdminArea" */ "./TallyWebhooks.vue")
},
{
	path: "/Admin/NutritionistProfile/:UserId",
	component: () => import(/* webpackChunkName: "AdminArea" */ "./NutritionistProfile.vue")
},
{
	path: "/Admin/Nutritionists",
	component: () => import(/* webpackChunkName: "AdminArea" */ "./NutritionistsList.vue")
},
{
	path: "/Admin/Tags",
	component: () => import(/* webpackChunkName: "userEditorViews" */ "./Tags.vue")
},
{
	path: "/Admin/Whatsapp",
	component: () => import(/* webpackChunkName: "AdminArea" */ "./WhatsappMessages.vue")
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["BusinessAdmin"]
	};
}
);

export default routes;
