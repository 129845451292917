const routes = [{
	path: "/SystemSettings",
	component: () => import(/* webpackChunkName: "SystemAdmin" */ "./Index.vue")
},
{
	path: "/SystemSettings/LogViewer",
	component: () => import(/* webpackChunkName: "SystemAdmin" */ "./LogViewer.vue")
},
{
	path: "/SystemSettings/EmailTemplates",
	component: () => import(/* webpackChunkName: "SystemAdmin" */ "./EmailTemplates.vue")
},
{
	path: "/SystemSettings/Contracts",
	component: () => import(/* webpackChunkName: "SystemAdmin" */ "./Contracts.vue")
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: "SystemAdmin"
	};
}
);

export default routes;
