<template>
	<div class="mb-4">
		<ConfirmTool ref="confirm"></ConfirmTool>
		<div>
			<div class="poppins-medium text-center mb-3" style="font-size: 1.1rem;">
				<v-icon class="mr-2">fas fa-calendar-alt</v-icon>
        {{ FormattedBookingTime}}

			</div>
      <div class="poppins-semibold text-center mb-3" style="font-size: 1rem;">
      </div>
<!--			<div class="text-center mb-3">-->
<!--				<nutriYouBookingTypeChip small :StatusId="booking.BookingTypeId" class="mr-3"></nutriYouBookingTypeChip>-->

<!--				<nutriYouOrderStatusChip small :StatusId="booking.BookingStatusId"></nutriYouOrderStatusChip>-->
<!--			</div>-->
			<div class="text-center mb-3">
				<v-btn v-if="booking.BookingStatusId === BookingStatusIdEnum.BOOKED && DefaultMeetingRoomUrl"
					class="text-capitalize" color="nyrose" :href="DefaultMeetingRoomUrl" target="_blank" dark>
					<v-icon left>fas fa-video</v-icon>
					Vai alla Videochiamata
				</v-btn>
			</div>
		</div>
		<div class="text-center mb-3">
			<StripeCheckoutButton v-if="booking.BookingStatusId === BookingStatusIdEnum.WAITINGFORPAYMENT"
				:booking="booking" color="primary" class="text-capitalize"
				@RequireBillingProfile="RequireBillingProfile"
        @RequirePrivacyContract="RequirePrivacyContract"
        ref="stripeButton">
      </StripeCheckoutButton>

			<AddEditBillingProfile
        v-model="showAddBillingProfile"
        :mode="1"
        @updated="OnBillingProfileUpdated"
				Header="Mancano i dati per la fattura">
			</AddEditBillingProfile>

      <PrivacyContractsDialog v-model="showPrivacyContracts"
        @contractsAccepted="OnPrivacyContractAccepted" />

		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import ConfirmTool from "@/components/Shared/Common/Confirm";
// import nutriYouOrderStatusChip from "@/components/NutriYou/nutriYouOrderStatusChip";
// import nutriYouBookingTypeChip from "@/components/NutriYou/nutriYouBookingTypeChip";
import StripeCheckoutButton from "./StripeCheckoutButton";
import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";

import AddEditBillingProfile from "@/components/Account/AddEditBillingProfile";
import moment from "moment";
import PrivacyContractsDialog from "@/views/Patients/components/PrivacyContractsDialog.vue";

export default {
	components: {
		PrivacyContractsDialog,
		// nutriYouOrderStatusChip,
		// nutriYouBookingTypeChip,
		ConfirmTool,
		StripeCheckoutButton,
		AddEditBillingProfile,
	},
	props: {
		booking: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showModifyDialog: false,
			newDataTime: null,
			showAddBillingProfile: false,
			DefaultMeetingRoomUrl: null,
			showPrivacyContracts: false
		};
	},
	computed: {
		FormattedBookingTime() {
			moment.locale("it");

			// if this.booking.BookingTime is within 3 days time
			const now = moment();
			const bookingTime = moment(this.booking.BookingTime);
			if (bookingTime.diff(now, "days") <= 3) {
				// your logic here
				return moment(this.booking.BookingTime).calendar();
			}

			return moment(this.booking.BookingTime).format("LLLL");
		}
	},
	created() {
		this.BookingStatusIdEnum = BookingStatusIdEnum;
		this.NutritionistsService = new CrudClient("Nutritionists/Public");
	},
	async mounted() {
		if (this.booking.BookingStatusId === BookingStatusIdEnum.BOOKED) {
			const nutritionist = await this.NutritionistsService.Get(this.booking.NutritionistUserId);
			this.DefaultMeetingRoomUrl = nutritionist.DefaultMeetingRoomUrl;
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		RequireBillingProfile() {
			this.showAddBillingProfile = true;
		},

		OnPrivacyContractAccepted() {
			this.$refs.stripeButton.redirectToStripe();
		},

		RequirePrivacyContract() {
			this.showPrivacyContracts = true;
		},

		OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;

			this.$refs.stripeButton.redirectToStripe();
		},
	},
};
</script>
<i18n>
{
	"it":{
		"CancelBooking": "Annulla l'appuntamento",
		"CompleteBooking": "Completa"
	}
}
</i18n>
