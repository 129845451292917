<script>
export default {
	name: "PatientNoMatching"
}
</script>

<template>
  <v-card class="ma-5 pa-5">
    <v-card-text>
      <p>
        Il nostro algoritmo sta cercando il nutrizionista <span class="font-weight-bold">su misura per
							te</span>.
      </p>
      <p>
        Di solito c'è bisogno di <span class="font-weight-bold">qualche ora</span> per cui ti chiediamo
        <span class="font-weight-bold">un attimo di pazienza</span> 🙂
      </p>
      <p>
        Appena trovato ti invieremo una email per avvisarti!
      </p>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">

</style>
