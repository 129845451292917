<template>
	<div>
		<div v-if="Loading">
			<div class="limitWidth">
				<h1 class="nutriyou-h2 primary--text">
					Stiamo preparando il tuo account!
				</h1>
				<h1 class="nutriyou-h3 primary--text">
					Un attimo di pazienza.
				</h1>
				<v-progress-linear class="mx-8 my-4" :value="progress"></v-progress-linear>
			</div>
		</div>
		<div v-if="VerificationComplete" class="mx-10">
			<h1 class="nutriyou-h1">Email confermata correttamente! 🎉</h1>

			<p class="text-center">
				La tua Area Personale adesso è pronta! Imposta la tua password per accedere la prossima volta.
			</p>

			<SetPasswordCard class="mx-auto" style="max-width: 600px" @passwordSet="OnPasswordSet"></SetPasswordCard>
		</div>
    <v-card color="nyroseLight" v-if="VerificationError" class="mb-3">

      <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
        La tua e-mail ci risulta già presente su Nutriyou.
      </div>
      <div class="poppins-regular text-center">
        Se non sai come accedere al tuo profilo, <router-link class="font-weight-bold"
                                                              to="/RecoverPassword">recupera la tua password</router-link> e inizia o continua con il nutrizionista
        che ti era stato assegnato al
        momento della prima compilazione.
        <br />
        Se invece desideri cambiare nutrizionista o hai avuto qualche problema di altro tipo scrivici su
        WhatsApp o
        via e-mail a supporto@nutriyou.it
      </div>
    </v-card>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import SetPasswordCard from "../Patients/components/SetPassword";
import { mapActions } from "vuex";

export default {
	components: { SetPasswordCard },
	computed: {
		VerificationToken() {
			return this.$route.params.verificationToken;
		},
	},
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			Loading: false,
			VerificationComplete: false,
			VerificationError: false,
			progress: 0
		};
	},
	created() {
		this.TallyWebhookService = new CrudClient("TallyWebhook");
	},
	async mounted() {
		window.addEventListener("beforeunload", this.onBeforeUnload);

		if (!navigator.userAgent.startsWith("WhatsApp/2")) {
			await this.VerifyToken();
		}
	},
	beforeDestroy() {
		// window.removeEventListener("beforeunload", this.onBeforeUnload);
	},
	methods: {
		...mapActions([
			"LoadUserData",
			"refreshTokenFromInstantLoginToken",
			"RefreshAccessToken",
			"snackSuccess",
			"snackError",
		]),

		onBeforeUnload(e) {
			if (this.Loading) {
				e.preventDefault();
				e.returnValue = "";
				return;
			}

			delete e.returnValue;
		},
		async VerifyToken() {
			try {
				this.Loading = true;

				const step = 5;
				const interval = 8000 / 20; // Interval in milliseconds
				const timer = setInterval(() => {
					this.progress += step;
					console.log(this.value); // Output the current value
					if (this.value >= 100) {
						clearInterval(timer); // Stop the interval when the value reaches or exceeds 100
					}
				}, interval);

				const instantAccessToken = await this.TallyWebhookService.Post(
					null,
					null,
					`${this.VerificationToken}/Verify`,
					true
				);

				if (instantAccessToken?.message === "User already exists.") {
					this.VerificationError = true;
					return;
				}

				if (instantAccessToken) {
					await this.refreshTokenFromInstantLoginToken(instantAccessToken);
					window.fbq("track", "CompleteRegistration");
				}

				// try to refresh token
				await this.RefreshAccessToken(true);

				await this.LoadUserData();

				this.VerificationComplete = true;
				this.$router.push("/");
			} catch (error) {
				this.$captureError(error);
				this.VerificationError = true;
			} finally {
				this.Loading = false;
			}
		},

		OnPasswordSet() {
			// redirect to home
			this.$router.push("/");
		}
	},
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Email confermata correttamente",
		"pageMetaDescription": ""
	}
}
</i18n>
<style lang="scss" scoped>
.limitWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
</style>
