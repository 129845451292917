<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>
		<div class="limitWidth">
			<div v-if="LoadingData">
				<v-skeleton-loader width="500" type="list-item-avatar-two-line"></v-skeleton-loader>
				<v-skeleton-loader v-for="i in 3" :key="i" type="article" class="mx-auto"></v-skeleton-loader>
			</div>
			<div v-if="nutritionistData">
				<v-container fluid>
					<v-row>
						<v-col cols="12" md="6">
							<v-container fluid>
								<v-row>
									<v-col cols="5"><v-img :src="nutritionistData.ImageFileUrl"></v-img></v-col>
									<v-col cols="7">
										<h1 class="nutriyou-h2 text-left primary--text mb-1" style="font-size: 2rem;">
											{{ nutritionistData.ApplicationUser.DisplayName }}
										</h1>
										<div class="poppins-medium" style="font-size: 1rem;">
											{{ nutritionistData.ProfessionalTitle}}
										</div>
									</v-col>
								</v-row>
							</v-container>

						</v-col>
						<v-col cols="12">
							<div>
								<div class="poppins-medium mb-2" style="font-size: 1.3rem;">Mi
									presento</div>
								<p v-for="part in BioParts" :key="part" class="poppins-regular">
									{{ part }}
								</p>
							</div>

						</v-col>
					</v-row>
				</v-container>

				<div>
					<h2 class="nutriyou-h3 nyrose--text mb-0">Formazione</h2>
					<div class="poppins-medium mb-2" style="font-size: 1rem;">
						{{ nutritionistData.ProfessionalTitle}}
					</div>

					<div v-for="part in TrainingParts" :key="part" class="poppins-regular">
						<template v-if="!part.startsWith('Iscrizione ') && !part.startsWith('Esercita')">
							<v-icon class="nyrose--text mr-2" small>fas fa-check</v-icon>
							{{ part }}
						</template>
						<template v-else>
							<div class="poppins-bold grey--text text--darken-2 mt-2">
								{{ part }}
							</div>
						</template>
					</div>
				</div>

				<div class="poppins-regular">
					<h2 class="nutriyou-h3 mt-5 nyGreenAlt--text font-weight-bold">
						Ambiti di specializzazione
					</h2>
					<div v-for="tag in userTags" :key="tag.UserSkillId">
						<v-icon class="nyGreenAlt--text mr-2" small>fas fa-check</v-icon>
						{{ tag.Skill.Name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	metaInfo() {
		return {
			title: this.nutritionistData?.ApplicationUser.DisplayName,
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			nutritionistData: null,
			LoadingData: false,

			userTags: null,
		};
	},
	computed: {
		FriendlyUrl() {
			return this.$route.params.friendyUrl;
		},

		TrainingParts() {
			if (!this.nutritionistData) return null;
			return this.nutritionistData.Training.split(/\r?\n/).filter((i) => i);
		},

		BioParts() {
			if (!this.nutritionistData) return null;
			return this.nutritionistData.Bio.split(/\r?\n/).filter((i) => i);
		},
	},

	created() {
		// add services to this
		this.NutritionistsService = new CrudClient("Nutritionists/Public");
		this.UserTags = new CrudClient("SGP/UserTags");
	},

	async mounted() {
		try {
			this.LoadingData = true;
			const nutritionistData = await this.NutritionistsService.Get(
				`FriendlyUrl/${this.FriendlyUrl}`
			);

			this.userTags = (
				await this.UserTags.Get("User/" + nutritionistData.UserId)
			).Data;

			this.nutritionistData = nutritionistData;
		} catch (error) {
			this.$captureError(error);
			this.snackError({ Text: this.$t("brand.error.cannotLoadData") });
		} finally {
			this.LoadingData = false;
		}

		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.nutritionists"),
			disabled: false,
			exact: true,
			to: "/Nutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.nutritionistData.ApplicationUser.DisplayName,
			disabled: true,
			exact: true,
			to: "/Nutritionists/" + this.nutritionistData.ProfilePageUrl,
		});
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
	},
};
</script>
<i18n>
{
	"it":{
		"a": "a"
	}
}
</i18n>
<style lang="scss" scoped>
.limitWidth {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}
</style>
