<template>
<div v-if="OnboardingStatus !== null">
  <PatientNoMatching v-if="OnboardingStatus === 0"></PatientNoMatching>
  <PatientOnboarding :OnboardingStatus="OnboardingStatus"></PatientOnboarding>
</div>

</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import PatientOnboarding from "@/views/Patients/components/PatientOnboarding.vue";
import PatientNoMatching from "@/views/Patients/components/PatientNoMatching.vue";

export default {
	components: {
		PatientNoMatching,
		PatientOnboarding,

	},
	data() {
		return {
			LoadingData: false,
			OnboardingStatus: null,
			showPrivacyContract: false
		}
	},
	computed: {
		...mapGetters(["UserProfile"]),
	},
	created() {
		this.CurrentPatientProfileService = new CrudClient("CurrentPatient/Profile");
	},
	async mounted() {
		// const PatientProfile = await this.CurrentPatientProfileService.Get();
		// if (PatientProfile.DefaultNutritionistUserId === null) {
		// 	this.showNoMatching = true;
		// 	return;
		// }

		await this.GetOnboardingStatus();
		// await this.GetContractStatus();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async GetOnboardingStatus() {
			this.LoadingData = true;
			try {
				this.OnboardingStatus = await this.CurrentPatientProfileService.Get("OnboardingStep");
				this.$log.debug({ OnboardingStatus: this.OnboardingStatus });
			} finally {
				this.LoadingData = false;
			}
		},

		async GetContractStatus() {
			const patientDetails = await this.CurrentPatientProfileService.GetSelectedFields(null, "PrivacyContractSigned");

			// check if Nutritionist Billing Profile is complete
			const nutritionistBillingProfileStatus = await this.CurrentPatientNutritionistService.Get(null, "IsBillingProfileComplete");

			if (nutritionistBillingProfileStatus.IsBillingProfileComplete && patientDetails.PrivacyContractSigned === true) {
				this.showPrivacyContract = true
			}
		}

	}
}
</script>
<i18n>
	{
		"it":{
			"NoBookings": "Nessun appuntamento fissato."
		}
	}
</i18n>
<style lang="scss" scoped>
.limitWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
</style>
