<template>
	<div class="chat-wrapper">
		<div class="rounded" :class="{ 'elevation-5': !$vuetify.breakpoint.xs }">

      <v-toolbar color="blue-grey lighten-4 ">
				<v-btn
					icon
					v-if="isUserInRole('Nutritionist') && $vuetify.breakpoint.xs && currentConversationUserId"
					@click="ResetCurrentConversationUserId"
				>
					<v-icon>fas fa-arrow-circle-left</v-icon>
				</v-btn>
        <v-toolbar-title v-if="isUserInRole('Nutritionist')">
					<span v-if="$vuetify.breakpoint.xs && CurrentConversationView">{{
						CurrentConversationView.DisplayName
					}}
					</span>
					<span v-else>{{ $t("pageTitle") }}</span>
				</v-toolbar-title>
        <span v-if="isUserInRole('Patient') && CurrentConversationView">
          <span v-if="$vuetify.breakpoint.xs">
             <user-avatar-fullname
               style="display: inline-block"
               :showDisplayName="true"
               :profile="CurrentConversationView"
               :size="40"
             ></user-avatar-fullname>
            <v-icon
              style="display: inline"
              size="14"
              :color="CurrentConversationView.isOnline ? 'green' : 'grey'"
            > fas fa-circle</v-icon>
          </span>
          <v-toolbar-title v-else>{{ $t("pageTitle") }}</v-toolbar-title>

        </span>

				<v-spacer></v-spacer>

				<span v-if="isUserInRole('Nutritionist') && $vuetify.breakpoint.xs && currentConversationUserId">
					<NutritionistListItemActions
            @conversationArchived="CurrentConversationView.IsArchived = 1"
            @conversationUnarchived="CurrentConversationView.IsArchived = 0"
            :Conversation="CurrentConversationView">
          </NutritionistListItemActions>
				</span>
			</v-toolbar>

			<v-container fluid class="chat-container">
				<v-row>
					<v-col
						cols="12"
						sm="4"
						class="grey lighten-5 pa-0 chatList"
						v-if="
							(!currentConversationUserId && $vuetify.breakpoint.xs) ||
							!$vuetify.breakpoint.xs
						"
					>
          <NutritionistConversationsList  v-if="isUserInRole('Nutritionist')">
          </NutritionistConversationsList>

            <PatientConnectToNutritionist v-if="isUserInRole('Patient')"></PatientConnectToNutritionist>
					</v-col>
					<v-col
						cols="12"
						sm="8"
						class="orange lighten-5 pa-0"
						v-if="
							(currentConversationUserId && $vuetify.breakpoint.xs) ||
							!$vuetify.breakpoint.xs
						"
					>
						<!-- Passed also key prop to force rerender https://michaelnthiessen.com/force-re-render/ -->
						<ChatWindow
							v-if="currentConversationUserId"
							:key="currentConversationUserId"
						></ChatWindow>
						<template v-else>
							<v-row
								class="flex-column align-center justify-center"
								ma-5
								style="height: 100%"
							>
								<v-icon size="160" color="secondary lighten-2" class="shadow ma-3"
									>fas fa-comments-alt</v-icon
								>
							</v-row>
						</template>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
import ChatWindow from "./ChatWindow.vue";

import { mapGetters, mapActions } from "vuex";

import CrudClient from "@/services/CrudClient/";
import NutritionistListItemActions from "./NutritionistListItemActions"
import NutritionistConversationsList from "@/components/Chat/NutritionistConversationsList.vue";
import PatientConnectToNutritionist from "@/components/Chat/PatientConnectToNutritionist.vue";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname.vue";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{
				name: "description",
				content: this.$t("pageTitle")
			}],
		};
	},
	components: {
		UserAvatarFullname,
		PatientConnectToNutritionist,
		ChatWindow,
		NutritionistConversationsList,
		NutritionistListItemActions
	},
	data() {
		return {
			chatService: new CrudClient("Chat"),
			creatingConversation: false
		};
	},
	computed: {
		...mapGetters([
			"CombinedConversationView",
			"TwilioChatClient",
			"isLoadingConversationView",
			"currentConversationUserId",
			"isUserInRole"
		]),

		CurrentConversationView() {
			if (this.currentConversationUserId) {
				return this.CombinedConversationView.find((c) => c.UserId === this.currentConversationUserId);
			} else {
				return null;
			}
		}
	},
	watch: {
		// reasoning:
		// instead of setting the conversation in mounted
		// watch isLoadingConversationView to have the loaded conversations list
		isLoadingConversationView: {
			immediate: true,
			async handler(newVal) {
				this.$log.debug("watch isLoadingConversationView " + newVal);
				if (this.CombinedConversationView && !newVal) {
					this.$log.debug("isLoadingConversationView false arrived");

					await this.LoadInitialConversation();
				}
			}
		}
	},
	async mounted() {
		this.SetChatWindowState(true);
		this.setActualVHProperty();
		window.addEventListener("resize", this.setActualVHProperty);

		// // reasoning: if another page is opened first and Conversations are already loaded, set the view immediately
		// if (!this.isLoadingConversationView) {
		// 	await this.LoadInitialConversation();
		// }
	},

	beforeDestroy() {
		this.SetChatWindowState(false);
		window.removeEventListener("resize", this.setActualVHProperty);
	},
	methods: {
		...mapActions([
			"snackError",
			"SetChatWindowState",
			"SetCurrentConversationUserId",
			"ResetCurrentConversationUserId",
			"LoadConversations"
		]),

		async LoadInitialConversation() {
			this.$log.debug("LoadInitialConversation starts");
			if (this.$route.query.UserId) {
				await this.setActiveConversationFromUserId(this.$route.query.UserId);
			} else if (this.CombinedConversationView.length === 1) {
				this.$log.debug("this.CombinedConversationView.length" + this.CombinedConversationView.length);
				// there is only one conversation, set it as active directly
				await this.SetCurrentConversationUserId(this.CombinedConversationView[0].UserId);
			}
		},

		setActualVHProperty() {
			// First we get the viewport height; we multiply it by 1% to get a value for a vh unit
			const vh = window.innerHeight * 0.01;
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},

		async setActiveConversationFromUserId(userId) {
			// TODO: if there is only one conversation set it as currents
			// TODO: check the commented cases

			// QueryString UserId ->
			// search in conversations
			const conversation = this.CombinedConversationView.find((c) => c.UserId.toLowerCase() === userId.toLowerCase());

			if (conversation) {
				this.$log.debug("setActiveConversationFromUserId: SetCurrentConversationId 1");
				await this.SetCurrentConversationUserId(userId);
			} else {
				this.$log.error("setActiveConversationFromUserId: it shouldn't arrive");
			}
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
$headerHeight: 56px;

.shadow {
	text-shadow: -7px 8px 2px map-get($grey, "lighten-2"),
		-3px 5px map-get($grey, "lighten-3");
}

.v-list.theme--light {
	.conversationItem {
		a {
			&.v-list__tile--link {
				&:hover {
					background-color: transparent;
				}
			}
		}
		&:hover {
			background-color: map-get($grey, "lighten-5");
		}
		&:not(.active) {
			cursor: pointer;
		}
		&.active {
			background-color: map-get($grey, "lighten-3");
			border-color: map-get($grey, "lighten-3");
		}
	}
}

.chat-wrapper {
	@media #{map-get($display-breakpoints, 'xs-only')} {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: calc(100vh - #{$headerHeight});
		height: calc(#{"var(--vh, 1vh) * 100"} - #{$headerHeight});
		margin: -12px;
		& > .v-card {
			border-radius: 0 !important;
		}
	}
	.chatList {
		overflow-y: auto;
		height: calc(100vh - 200px);
		height: calc(#{"var(--vh, 1vh) * 100 - 200px"});
		@media #{map-get($display-breakpoints, 'xs-only')} {
			height: calc(100vh - #{$headerHeight} * 2);
			height: calc(#{"var(--vh, 1vh) * 100"} - #{$headerHeight * 2});
		}
		.chatConversation {
			flex-wrap: nowrap;
			.conversationName {
				display: flex;
				flex: auto;
				align-items: center;
			}
			.conversationActions {
				display: flex;
				flex: initial;
				min-width: 28px;
				justify-content: flex-end;
				.v-chip {
					width: 24px;
					border-radius: 50% !important;
					margin-right: 4px;
				}
			}
		}
		.onlineStatus {
			.v-badge {
				&__badge {
					$badgeSize: 18px;

					min-width: unset;
					border-radius: 50%;
					bottom: 0 !important;
					right: 0 !important;
					width: $badgeSize;
					height: $badgeSize;
					top: calc(100% - #{$badgeSize}) !important;
					left: calc(100% - #{$badgeSize}) !important;
				}
			}
		}
	}
}
</style>
<i18n>
 {
    "it": {
		"pageTitle": "Messaggi",
		"chats": "Conversazioni"
	}
}
</i18n>
