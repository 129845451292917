<template>
	<div class="limitWidth">

		<div v-if="!initialSetupComplete">
			<v-container>
				<v-row>
					<v-col cols="12" md="6">
						<v-skeleton-loader class="mx-auto border" max-width="300"
							type="image, article"></v-skeleton-loader>
					</v-col>

					<v-col cols="12" md="6">
						<v-skeleton-loader class="mx-auto border" max-width="300"
							type="image, article"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div v-else>

      <v-container fluid>
					<v-row >
            <v-col cols="12" order="1" order-md="1">
              <v-card color="" flat>
                <v-card-text>
                  <div class="poppins-semibold nyGreenAlt--text text--darken-2 mb-2" style="font-size: 0.8rem">
                    Ciao {{ UserProfile.Name }}!
                  </div>
                  <div
                       class="poppins-regular"
                       :class="{
              'WelcomeTextMessage': $vuetify.breakpoint.smAndDown,
              'WelcomeTextMessageMdAndUp':  $vuetify.breakpoint.mdAndUp}"
                  >

                    <template v-if="OnboardingStatus === 1">Abbiamo trovato {{ NutritionistProfile.Gender === 1 ? 'il tuo ' : 'la tua '}} nutrizionista!</template>
                    <template v-if="OnboardingStatus === 2">Il tuo percorso sta per iniziare!</template>
                    <template v-if="OnboardingStatus === 3">Il cambiamento inizia oggi!</template>
                    <template v-if="OnboardingStatus === 4">
                      <span v-if="!hasExpiringBooking">Un piccolo promemoria: la visita aspetta il suo pagamento!</span>
                      <span v-else> <v-icon size="18">fas fa-alarm-exclamation</v-icon> Ultima chiamata: manca poco per pagare e confermare!</span>
                    </template>
                    <template v-if="OnboardingStatus === 5">Manca poco alla tua visita nutrizionale</template>
                    <template v-if="OnboardingStatus === 6">Il tuo percorso è appena iniziato!</template>
                    <template v-if="OnboardingStatus === 7">
                      <span v-if="!hasExpiringBooking">
                        Un piccolo promemoria: la visita aspetta il suo pagamento!
                      </span>
                      <span v-else> <v-icon size="18">fas fa-alarm-exclamation</v-icon> È quasi ora! Ricorda che la scadenza per il pagamento si avvicina!</span>

                    </template>
                    <template v-if="OnboardingStatus === 8">Manca poco alla tua visita nutrizionale</template>
                    <template v-if="OnboardingStatus === 9">Non fermarti: il tuo viaggio è in piena crescita! 🌱
                    </template>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

						<v-col cols="12" md="6" order="1" order-md="2">
              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 1" to="/Chat">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
                    Prenota l'incontro conoscitivo</div>
                  <div class="poppins-regular text-center">
                    Scrivi ora {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutritionista per
                    prenotare    <span class="font-weight-bold">l'incontro
                    gratuito</span>.

<br />
<!--                    <br />-->
<!--                    Prenota una videochiamata gratuita di 20-30 minuti e conosci il professionista che ti guiderà verso una vita più sana.-->
<!--                    È l'occasione perfetta per parlare dei tuoi obiettivi e capire come possiamo aiutarti a raggiungerli.-->
<!--                    Clicca qui per fissare il tuo appuntamento e inizia il tuo viaggio verso il benessere!-->
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 2">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
                    Hai l'incontro conoscitivo gratuito fissato</div>
                  <div></div>
                  <div class="poppins-regular text-center">
                    <div v-for="booking in bookings" :key="booking.BookingId">
                      <MiniBookingCard :booking="booking">
                      </MiniBookingCard>
                    </div>
                  </div>
                  <div class="poppins-regular">
                    Se avrai un imprevisto potrai sempre spostare o annullare la videochiamata.
                    <br />
                    Ti basterà inviare un messaggio {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 3">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
                   Prenota la tua prima visita</div>
                  <div class="poppins-regular text-center">
                    Scrivi {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat per prenotare
                    la tua prossima visita nutrizionale
                    e ricevere un supporto su misura per raggiungere i tuoi obiettivi.
                    <br />

                    <div class="text-center mt-3">
                    <v-btn class="text-transform-none" dark large color="nyrose" to="/Chat">
                      <v-icon left>fas fa-fw fa-comment-alt-dots</v-icon>
                      Scrivi {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista
                    </v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 4 || OnboardingStatus === 7">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
                    Il prossimo appuntamento</div>

                  <div class="poppins-regular text-center">
                    <div v-for="booking in bookings.filter(b => b.BookingTypeId != BookingTypesEnum.FREE && b.BookingStatusId == BookingStatusIdEnum.WAITINGFORPAYMENT)" :key="booking.BookingId">
                      <MiniBookingCard :booking="booking">
                      </MiniBookingCard>
                    </div>
                  </div>

                  <div class="poppins-regular">
                    Se avrai un imprevisto potrai spostare o annullare la videochiamata entro 2 giorni dall'appuntamento.
                    <br />
                    Ti basterà inviare un messaggio {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat.
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 5 || OnboardingStatus === 8">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
                    Il prossimo appuntamento</div>
                  <div></div>
                  <div class="poppins-regular text-center">
                    <div v-for="booking in bookings.filter(b => b.BookingTypeId != BookingTypesEnum.FREE && b.BookingStatusId == BookingStatusIdEnum.BOOKED)" :key="booking.BookingId">
                      <MiniBookingCard :booking="booking">
                      </MiniBookingCard>
                    </div>
                  </div>
                  <div class="poppins-regular">
                    Se avrai un imprevisto potrai spostare o annullare la videochiamata entro 2 giorni dall'appuntamento.
                    <br />
                    Ti basterà inviare un messaggio  {{ NutritionistProfile.Gender === 1 ? 'al tuo ' : 'alla tua '}} nutrizionista in chat
                  </div>
                </v-card-text>
              </v-card>

              <v-card color="nyroseLight" class="mb-3" v-if="OnboardingStatus === 6 || OnboardingStatus === 9">
                <v-card-text>
                  <div class="nyrose--text TitleFont text-center mb-3" style="font-size: 1rem;">
                    Prenota il tuo prossimo appuntamento</div>
                  <div></div>
                  <div class="poppins-regular mb-2">
                    Appuntamenti regolari possono essere di grande aiuto per raggiungere i tuoi obiettivi e mantenere il tuo equilibrio.

                  </div>

                  <div class="poppins-regular text-center">
                    <v-btn color="nyrose"
                           class="text-transform-none"
                           dark block large
                           to="/Chat">
                      <v-badge color="red" v-if="TotalNewMessages > 0" overlap>
                        <template v-slot:badge>
                          <span>{{ TotalNewMessages }}</span>
                        </template>
                        <v-icon color="white" left>fas fa-fw fa-comment-alt-dots</v-icon>
                      </v-badge>
                      <v-icon color="white" left v-else>fas fa-fw fa-comment-alt-dots</v-icon>
                      <span :class="{ 'ml-3': TotalNewMessages}">
              {{ TotalNewMessages > 0 ?
                        (NutritionistProfile.Gender === 1 ? "Rispondi al tuo nutrizionista" : "Rispondi alla tua nutrizionista" ):
                        (NutritionistProfile.Gender === 1 ? "Scrivi al tuo nutrizionista" : "Scrivi alla tua nutrizionista")
                        }}</span>
                    </v-btn>

                  </div>

                </v-card-text>
              </v-card>

              <template v-if="$vuetify.breakpoint.mdAndUp">
                  <AssistanceBox></AssistanceBox>
                  <SetPasswordCard class="mx-auto"></SetPasswordCard>
              </template>
            </v-col>
            <v-col cols="12" md="6" order="2" order-md="1"     v-if="initialSetupComplete">
<!--              <MiniBookingWithProfile :nutritionist-profile="NutritionistProfile"></MiniBookingWithProfile>-->
							<NutritionistProfileExpandableCard
                 :matching-skills="matchingSkills"
                 :nutritionist-profile="NutritionistProfile"
                 :nutritionist-tags="nutritionistTags">
                <template v-slot:mail-button v-if="OnboardingStatus === 1">
                  <v-btn color="nyGreenAlt darken-2"
                         class="text-transform-none"
                         dark block large
                         to="/Chat">
                    <v-badge color="red" v-if="TotalNewMessages > 0" overlap>
                      <template v-slot:badge>
                        <span>{{ TotalNewMessages }}</span>
                      </template>
                      <v-icon color="white" left>fas fa-fw fa-comment-alt-dots</v-icon>
                    </v-badge>
                    <v-icon color="white" left v-else>fas fa-fw fa-comment-alt-dots</v-icon>
                    <span :class="{ 'ml-3': TotalNewMessages}">Prenota l'incontro gratuito</span>
                  </v-btn>
                </template>
              </NutritionistProfileExpandableCard>

						</v-col>
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-col cols="12" md="6" order="3">
                <AssistanceBox></AssistanceBox>
              </v-col>
              <v-col cols="12" md="6" order="4">
                <SetPasswordCard class="mx-auto"></SetPasswordCard>
              </v-col>
            </template>
					</v-row>
				</v-container>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SetPasswordCard from "./SetPassword";
import CrudClient from "@/services/CrudClient/";
import NutritionistProfileExpandableCard from "@/views/Patients/components/NutritionistProfileExpandableCard.vue";
import AssistanceBox from "@/views/Patients/components/AssistanceBox.vue";
import MiniBookingCard from "@/views/Patients/components/MiniBookingCard.vue";
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";
import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";
import moment from "moment/moment";

export default {
	components: {
		MiniBookingCard,
		AssistanceBox,
		NutritionistProfileExpandableCard,
		SetPasswordCard
	},
	data() {
		return {
			LoadingData: false,
			bookings: [],

			PatientProfile: null,
			NutritionistProfile: null,
			initialSetupComplete: false,
			matchingSkills: [],
			nutritionistTags: [],
			hasExpiringBooking: false
		}
	},
	props: {
		OnboardingStatus: {
			type: Number,
			required: true
		}
	},
	computed: {
		BookingStatusIdEnum() {
			return BookingStatusIdEnum
		},
		BookingTypesEnum() {
			return BookingTypesEnum
		},
		...mapGetters(["UserProfile", "TotalNewMessages"]),
	},
	created() {
		this.CurrentPatientProfileService = new CrudClient("CurrentPatient/Profile");
		this.BookingsService = new CrudClient("CurrentPatient/Bookings");
		this.CurrentPatientNutritionistService = new CrudClient("CurrentPatient/Nutritionist");
		this.PublicNutritionistsService = new CrudClient("Nutritionists/Public");
		this.UserTags = new CrudClient("SGP/UserTags");
	},
	async mounted() {
		this.PatientProfile = await this.CurrentPatientProfileService.Get();
		if (this.PatientProfile.DefaultNutritionistUserId) {
			await this.LoadBookings();

			this.NutritionistProfile = await this.PublicNutritionistsService.Get(this.PatientProfile.DefaultNutritionistUserId);
			try {
				this.matchingSkills = await this.CurrentPatientNutritionistService.Get("MatchingSkills");
			} catch (error) {
				this.matchingSkills = [];
				this.$captureError(error);
			}

			try {
				this.nutritionistTags = (
					await this.UserTags.Get("User/" + this.PatientProfile.DefaultNutritionistUserId)
				).Data;
			} catch (error) {
				this.$captureError(error);
			}
		}
		this.initialSetupComplete = true;
	},

	beforeDestroy() {
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		getDateString() {
			const today = new Date();
			// Attention: Janyary is 0
			return `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;
		},
		async LoadBookings() {
			try {
				const orderBy = "BookingTime:asc";

				const formattedToday = this.getDateString();

				let filter = `BookingTime:gt:${formattedToday}`;

				filter += ";BookingStatusId:in:1,2,4"

				const pageSize = 3;

				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					limit: pageSize,
					skip: 0,
					fields: "*",
					filter: filter,
					orderBy: orderBy,
				});
				this.bookings = res.Data;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}

			// check if there is a booking with waiting payment state and near to expire date
			const now = moment();

			this.hasExpiringBooking = this.bookings
				.filter(b => b.BookingTypeId !== BookingTypesEnum.FREE && b.BookingStatusId === BookingStatusIdEnum.WAITINGFORPAYMENT)
				.some(b => (moment(b.BookingTime).diff(now, "days") <= 3));
		},
	}
}
</script>
<i18n>
	{
		"it":{
		}
	}
</i18n>
<style lang="scss" scoped>
@import "@/assets/css/variables.scss";
.limitWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.BorderedBox{
  background-color: $ny_blueLight;
  border: 2px solid $ny_blue!important;
}

.WelcomeTextMessage {
  font-size: 1.2rem;
}

.WelcomeTextMessageMdAndUp {
  font-size: 1.6rem;
}
</style>
