const routes = [{
	path: "/Patients",
	component: () => import(/* webpackChunkName: "PatientArea" */ "./Index.vue")
},
{
	path: "/Patients/Agenda",
	component: () => import(/* webpackChunkName: "PatientArea" */ "./Bookings.vue")
},
{
	path: "/Patients/Agenda/:BookingId",
	component: () => import(/* webpackChunkName: "PatientArea" */ "./BookingDetails.vue")
},

{
	path: "/Patients/Agenda/:BookingId/Success",
	component: () => import(/* webpackChunkName: "PatientArea" */ "./BookingSuccess.vue")
},
{
	path: "/Patients/PrivacyNutriyou",
	component: () => import(/* webpackChunkName: "PatientArea" */ "./ContractViewer.vue"),
	props: {
		ContractTypeId: 2
	}
},
{
	path: "/Patients/InformativaPrivacy",
	component: () => import(/* webpackChunkName: "PatientArea" */ "./ContractViewer.vue"),
	props: {
		ContractTypeId: 3
	}
},
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["Patient"]
	};
}
);

export default routes;
